import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { emitEvent } from "@analytics/emit";
import {
  BI_LANDING_FIELD,
  EventFields,
  EventNames,
  PERSONAL_OFFER_TYPE,
  PURCHASE_SOURCE,
} from "@analytics/enums";
import { fetchOfferByPricePointId } from "api/personalOffers";
import { fetchOffer } from "api/shop/offer";
import { getCashierSasEnabled } from "environment";
import { FAIL_AUTOLOGIN_OPEN_CAHSIER_STORAGE_KEY } from "src/constants";
import tangoAnalytics from "src/core/analytics";
import { StorageKeys } from "src/core/analytics/enums";
import { ValueType } from "src/core/analytics/storage/types";
import { Currency } from "src/enums";
import { LottieConfigWithPathOrData, Nullable } from "src/types/common";
import { PaymentProvider, PaymentTarget, PaymentType } from "src/types/payment";
import { PricePoint } from "src/types/personalOffer";
import { defaultRoute } from "src/ui/navigation/links";
import {
  getIsUseBasePriceFromOfferCurrentPriceEnabled,
  getIsWebviewOldOffersFallbackEnabled,
  getIsWebviewPersonalOffersEnabled,
} from "state/abTests";
import { RootState } from "state/delegate";
import {
  autoLoginSelectors,
  loginSelectors,
  serverOwnedConfigSelectors,
} from "state/selectors";
import { ThemeContext } from "ui/Theme";
import RefillHeader from "ui/bottomScreen/common/RefillHeader";
import Spinner from "ui/common/Spinner";
import sharedMessages from "ui/common/intl/sharedMessages";
import LottieAnimation from "ui/common/lottie/LottieAnimation";
import { ModifiedShopOffer } from "ui/common/webviewOffersView/types";
import {
  onErrorNotifyPlatform,
  transformFullOffer,
} from "ui/common/webviewOffersView/utils";
import useCurrencies from "ui/hooks/useCurrencies";
import BonusPurchaseContext, {
  BonusPurchaseContextProvider,
  BonusStatus,
} from "ui/modal/modalViews/buyCoins/BonusPurchaseContext";
import DrawerPaymentMethods from "ui/modal/modalViews/buyCoins/drawerPaymentMethods/DrawerPaymentMethods";
import { PaymentTypeTab } from "ui/modal/modalViews/buyCoins/types";
import useQuery from "ui/navigation/useQuery";
import { ShopOffer } from "ui/scenes/landingPage/types";
import { toOffer } from "ui/scenes/landingPagePhoenix/landingPageMapperUtils";
import styles from "./WebviewOffersView.scss";

const lottieConfig = {
  path: "https://resources.tango.me/sas/confetti/data.json",
  loop: false,
  renderer: "canvas",
  rendererSettings: {
    preserveAspectRatio: "xMidYMin slice",
  },
  autoplay: true,
} as LottieConfigWithPathOrData;

const PRICE_POINT_ID_PARAM = "pricePointId";
const MARKET_OFFER_ID_PARAM = "marketOfferId";
const CURRENCY_PARAM = "currency";
const ONLY_CRYPTO_PARAM = "onlyCrypto";
const PAYMENT_METHOD_PARAM = "method";
const PAYMENT_TARGET_PARAM = "paymentTarget";
const IN_APP_PURCHASE_SOURCE_IOS_PARAM = "InAppPurchaseSource";
const IN_APP_PURCHASE_SOURCE_ANDROID_PARAM = "inAppPurchaseSource";
const TRACKING_ID_PARAM = "trackingId";
const TRIGGER_ID_PARAM = "triggerId";
const CAMPAIGN_ID_PARAM = "campaignId";
const OFFER_NAME_PARAM = "offerName";

const override = [Currency.INR];

const selector = (state: RootState) => ({
  isAutoLoginFailure: autoLoginSelectors.getIsResultFailure(state),
  isAutoLoginSuccess: autoLoginSelectors.getIsResultSuccess(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isServerConfigLoading:
    serverOwnedConfigSelectors.getIsServerConfigLoading(state),
  isWebviewPersonalOffersEnabled: getIsWebviewPersonalOffersEnabled(state),
  isWebviewOldOffersFallbackEnabled:
    getIsWebviewOldOffersFallbackEnabled(state),
  isServerConfigLoaded:
    serverOwnedConfigSelectors.getIsServerConfigLoaded(state),
  isUseBasePriceFromOfferCurrentPriceEnabled:
    getIsUseBasePriceFromOfferCurrentPriceEnabled(state),
});

interface WebviewOffersViewProps {
  defaultCurrency?: Currency;
  initialPaymentType?: PaymentTypeTab;
  purchaseSource: PURCHASE_SOURCE;
  suggestedProvider: PaymentProvider;
  taxV2?: boolean;
}

const WebviewOffersView: React.FC<WebviewOffersViewProps> = (props) => {
  const {
    suggestedProvider,
    initialPaymentType = PaymentTypeTab.ALL_PAYMENTS,
    defaultCurrency,
    taxV2,
    purchaseSource,
  } = props;

  const {
    isAutoLoginFailure,
    isAutoLoginSuccess,
    isLoggedIn,
    isWebviewPersonalOffersEnabled,
    isWebviewOldOffersFallbackEnabled,
    isServerConfigLoaded,
    isUseBasePriceFromOfferCurrentPriceEnabled,
  } = useSelector(selector, shallowEqual);

  const { dark: isDark } = useContext(ThemeContext);
  const query = useQuery();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOffer, setSelectedOffer] =
    useState<Nullable<ModifiedShopOffer | ShopOffer>>(null);
  const [showError, setShowError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(!isLoggedIn);

  const pricePointIdParam = query.get(PRICE_POINT_ID_PARAM) as
    | string
    | undefined;
  const marketOfferIdParam = query.get(MARKET_OFFER_ID_PARAM);
  const currencyParam = query.get(CURRENCY_PARAM)?.toUpperCase();
  const currency = (currencyParam || defaultCurrency) as Currency | undefined;
  const onlyCryptoParam = Boolean(query.get(ONLY_CRYPTO_PARAM));
  const paymentMethod = query.get(PAYMENT_METHOD_PARAM)?.toLowerCase() as
    | PaymentType
    | undefined;
  const paymentTargetParam = query.get(PAYMENT_TARGET_PARAM)?.toLowerCase() as
    | PaymentTarget
    | undefined;
  const inAppPurchaseSource = (query.get(IN_APP_PURCHASE_SOURCE_IOS_PARAM) ||
    query.get(IN_APP_PURCHASE_SOURCE_ANDROID_PARAM)) as string | undefined;
  const trackingId = query.get(TRACKING_ID_PARAM) as string | undefined;
  const triggerId = query.get(TRIGGER_ID_PARAM) as string | undefined;
  const campaignId = query.get(CAMPAIGN_ID_PARAM) as string | undefined;
  const offerNameParam = query.get(OFFER_NAME_PARAM) as string | undefined;

  const { bonusData, status, setStatus, setBonusData, initTransactionId } =
    useContext(BonusPurchaseContext);

  const resetOffer = useCallback(() => {
    setSelectedOffer(null);
  }, [setSelectedOffer]);

  const handleDismiss = useCallback(() => {
    if (status === BonusStatus.VISIBLE) {
      emitEvent(EventNames.CLOSE_ROULETTE, {
        initTransactionId,
      });
    }
    resetOffer();
    setBonusData({});
  }, [resetOffer, setBonusData, initTransactionId, status]);

  const handleConfettiComplete = useCallback(() => {
    setStatus(BonusStatus.CLAIMED);
  }, []);

  const showErrorAndNotifyPlatform = () => {
    setShowError(true);
    onErrorNotifyPlatform();
  };

  useCurrencies({
    override,
    suggestedProvider,
  });

  const handleSetOldOffer = useCallback(
    (
      marketOfferId: string,
      currency: Currency,
      signal: AbortSignal,
      pricePointId?: string
    ) => {
      fetchOffer(
        {
          marketOfferId,
          currency,
        },
        { signal }
      ).then(({ item }) => {
        const offer = transformFullOffer(item);
        setSelectedOffer({
          ...offer,
          pricePointId: offer.pricePointId || pricePointId,
        });
      });
    },
    []
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (isServerConfigLoaded) {
      if (
        marketOfferIdParam &&
        isWebviewPersonalOffersEnabled &&
        pricePointIdParam &&
        currency
      ) {
        fetchOfferByPricePointId(pricePointIdParam, {
          signal: abortController.signal,
        })
          .then((data) => {
            if (!data && isWebviewOldOffersFallbackEnabled) {
              handleSetOldOffer(
                marketOfferIdParam,
                currency,
                abortController.signal,
                pricePointIdParam
              );

              return;
            }

            const pricePoint: PricePoint = {
              ...data.pricePoints[0],
              offers: data.pricePoints[0].offers.filter(
                (offer) => offer.marketOfferId === marketOfferIdParam
              ),
            };

            const offer = toOffer({
              pricePoint,
              purchaseWithWheel: false,
              isUseBasePriceFromOfferCurrentPriceEnabled,
            });
            setSelectedOffer({
              ...offer,
              personalOfferId: data.personalOfferId,
            });
          })
          .catch(() => {
            if (isWebviewOldOffersFallbackEnabled) {
              handleSetOldOffer(
                marketOfferIdParam,
                currency,
                abortController.signal,
                pricePointIdParam
              );
            }
          });

        return;
      } else if (marketOfferIdParam && currency) {
        handleSetOldOffer(
          marketOfferIdParam,
          currency,
          abortController.signal,
          pricePointIdParam
        );
      }
    }

    return () => {
      abortController.abort();
    };
  }, [
    currency,
    marketOfferIdParam,
    pricePointIdParam,
    isWebviewPersonalOffersEnabled,
    isWebviewOldOffersFallbackEnabled,
    isServerConfigLoaded,
    isUseBasePriceFromOfferCurrentPriceEnabled,
  ]);

  useEffect(() => {
    if (isAutoLoginSuccess) {
      setShowSpinner(false);
    }

    if (isAutoLoginFailure) {
      setShowSpinner(false);
      showErrorAndNotifyPlatform();
    }
  }, [isAutoLoginFailure, isAutoLoginSuccess]);

  const analyticsParams = useMemo(
    () => ({
      [EventFields.PURCHASE_SOURCE]: purchaseSource,
      [EventFields.IN_APP_PURCHASE_SOURCE]: inAppPurchaseSource,
      [BI_LANDING_FIELD.TRACKING_ID]: trackingId,
      [BI_LANDING_FIELD.TRIGGER_ID]: triggerId,
      [EventFields.CAMPAIGN_ID]: campaignId,
      [EventFields.OFFER_NAME]: selectedOffer?.offerId || offerNameParam,
      [EventFields.PERSONAL_OFFER_TYPE]: PERSONAL_OFFER_TYPE.PERSONAL_OFFER,
    }),
    [
      purchaseSource,
      inAppPurchaseSource,
      trackingId,
      triggerId,
      campaignId,
      selectedOffer?.offerId,
      offerNameParam,
    ]
  );

  useEffect(() => {
    const analyticsData = Object.fromEntries(
      Object.entries(analyticsParams).filter(([, value]) => value !== undefined)
    ) as ValueType;

    tangoAnalytics.then((analytics) =>
      analytics.Session.setItem(StorageKeys.WEBVIEW_OFFERS_DATA, analyticsData)
    );

    return () => {
      tangoAnalytics.then((analytics) => {
        analytics.Session.removeItem(StorageKeys.WEBVIEW_OFFERS_DATA);
      });
    };
  }, [analyticsParams]);

  if (showError) {
    enqueueSnackbar(intl.formatMessage(sharedMessages.somethingWentWrong), {
      autoHideDuration: 1500,
      variant: "warning",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    localStorage.setItem(FAIL_AUTOLOGIN_OPEN_CAHSIER_STORAGE_KEY, "true");

    return <Redirect to={defaultRoute} />;
  }

  if (!isLoggedIn) {
    showErrorAndNotifyPlatform();
  }

  if (showSpinner) {
    return <Spinner className={styles.spinner} />;
  }

  return (
    <div className={classnames(styles.root)} data-testid="coins-modal">
      <div className={styles.buyCoinsView} data-testid="buy-coins-view">
        {selectedOffer ? (
          <div className={styles.payments}>
            <RefillHeader
              onClose={handleDismiss}
              isOffersVisible={false}
              isWebviewPage
            />
            <DrawerPaymentMethods
              offer={selectedOffer}
              provider={suggestedProvider}
              onDismiss={handleDismiss}
              isTabsAvailable={false}
              initialPaymentType={initialPaymentType}
              nested={false}
              taxV2={taxV2}
              onlyCrypto={onlyCryptoParam}
              singlePaymentMethod={paymentMethod}
              paymentTarget={paymentTargetParam}
              preferredCurrency={
                "currency" in selectedOffer
                  ? selectedOffer?.currency
                  : undefined
              }
              isDark={isDark}
              analyticsParams={analyticsParams}
            />
          </div>
        ) : null}
      </div>
      {bonusData?.enabled && status > BonusStatus.ANIMATING && (
        <LottieAnimation
          lottieConfig={lottieConfig}
          className={styles.confetti}
          onComplete={handleConfettiComplete}
        />
      )}
    </div>
  );
};

const WebviewOffersViewWithBonus = (props: WebviewOffersViewProps) => (
  <BonusPurchaseContextProvider isBonusEnabled={getCashierSasEnabled()}>
    <WebviewOffersView {...props} />
  </BonusPurchaseContextProvider>
);

export default WebviewOffersViewWithBonus;
