import React, {
  FC,
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { ExtendedStreamInfo, StreamKind } from "src/types/richFragment/Stream";
import { getImageProxyResizeLiveEnabled } from "state/abTests";
import { RoundedNumber } from "ui/common/Formatted";
import Dimmer from "ui/common/streamTiles/components/cover/components/Dimmer";
import StreamerInfo from "ui/common/streamTiles/components/cover/components/StreamerInfo";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useLazyImage from "ui/hooks/useLazyImage";
import useStreamPreviewMobile from "ui/hooks/useStreamPreviewMobile";
import { ReactComponent as BattleIcon } from "img/ic_battle.svg";
import { ReactComponent as EyeIcon } from "img/ic_eye_24.svg";
import { ReactComponent as GiftIcon } from "img/ic_gift_filled.svg";
import { ReactComponent as GiftsIcon } from "img/ic_gift_filled_prem_party.svg";
import { ReactComponent as LandscapeIcon } from "img/ic_landscape.svg";
import { ReactComponent as PartyIcon } from "img/ic_party.svg";
import { ReactComponent as RotateDeviceIcon } from "img/ic_rotate_device.svg";
import { ReactComponent as SubscribeIcon } from "img/ic_subscribe_ticket_star.svg";
import styles from "./Cover.scss";

interface CoverProps {
  showStreamerInfo?: boolean;
  stream: ExtendedStreamInfo;
}

const Cover: FC<CoverProps> = ({ stream, showStreamerInfo = true }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const ref = useRef<HTMLImageElement>(null);
  const isPrivateStream = [
    StreamKind.PRIVATE,
    StreamKind.TICKET_PRIVATE,
  ].includes(stream.kind);
  const isImageProxyResizeLiveEnabled = useSelector(
    getImageProxyResizeLiveEnabled,
    shallowEqual
  );

  const icon = useMemo(() => {
    if (stream.landscape) {
      return isMobile ? (
        <RotateDeviceIcon className={styles.icon} />
      ) : (
        <LandscapeIcon className={styles.icon} />
      );
    }

    if (!stream.relatedStreams?.length) {
      return null;
    }

    return stream.tags?.includes("battle") ? (
      <BattleIcon className={styles.icon} />
    ) : (
      <PartyIcon className={styles.icon} />
    );
  }, [
    stream.tags,
    stream.relatedStreams,
    stream.landscape,
    isMobile,
    isPrivateStream,
  ]);

  const premiumGiftIcon = useMemo(
    () =>
      stream.relatedStreams?.length ? (
        <GiftsIcon className={styles.gift} />
      ) : (
        <GiftIcon className={styles.gift} />
      ),
    [stream.relatedStreams]
  );

  const { dimmerRef, isMobilePreviewAvailable } = useStreamPreviewMobile();

  const [previewSrc, setPreviewSrc] = useState(stream.thumbnail);

  useLayoutEffect(() => {
    if (!isImageProxyResizeLiveEnabled || !ref.current) {
      return;
    }

    const { width, height } = ref.current.getBoundingClientRect();
    const url = new URL(stream.thumbnail);
    url.searchParams.set(
      "resize",
      `${Math.floor(width)}:${Math.floor(height)}`
    );

    setPreviewSrc(url.toString());
  }, [isImageProxyResizeLiveEnabled, stream.thumbnail]);

  useLazyImage(ref, previewSrc);

  return (
    <Typography
      type={TYPOGRAPHY_TYPE.HEADLINE6}
      className={classnames(
        styles.root,
        styles[breakpoint],
        !showStreamerInfo && styles.streamInfoOnly,
        (!isHovered || isPrivateStream) && styles.visible,
        isPrivateStream && styles.private
      )}
      as="div"
      onMouseEnter={isPrivateStream ? undefined : handleMouseEnter}
      onMouseLeave={isPrivateStream ? undefined : handleMouseLeave}
    >
      <img className={styles.img} data-src={previewSrc} alt="" ref={ref} />
      {isPrivateStream && (
        <div className={styles.privateLayer}>
          {!!stream.restrictions.init?.gift && premiumGiftIcon}
          {!!stream.restrictions.init?.subscription && (
            <SubscribeIcon className={styles.subscribe} />
          )}
        </div>
      )}
      <div
        className={classnames(styles.streamInfo, {
          [styles.isMobilePreviewAvailable]: isMobilePreviewAvailable,
        })}
      >
        <EyeIcon className={styles.icon} />
        <RoundedNumber value={stream.viewerCount || 0} />
        {!!icon && (
          <>
            <div className={styles.divider} />
            {icon}
          </>
        )}
      </div>
      {showStreamerInfo && (
        <StreamerInfo
          className={classnames(styles.streamerInfo, {
            [styles.isMobilePreviewAvailable]: isMobilePreviewAvailable,
          })}
          breakpoint={breakpoint}
          stream={stream}
          broadcasterId={stream.broadcasterId}
        />
      )}
      {!isPrivateStream && (
        <Dimmer
          ref={dimmerRef}
          stream={stream}
          isHovered={isHovered}
          isMobilePreviewAvailable={isMobilePreviewAvailable}
        />
      )}
    </Typography>
  );
};

export default memo(Cover);
