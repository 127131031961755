import React, { memo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { makeLinkToLiveStream } from "ui/navigation/links";
import Cover from "ui/common/streamTiles/components/cover/Cover";
import { TileProps } from "ui/common/streamTiles/components/types";
import { StreamKind } from "src/types/richFragment/Stream";
import { usePremiumEnabled } from "ui/hooks/killSwitch";
import StreamerAvatar from "ui/common/streamTiles/components/StreamerAvatar";
import styles from "ui/common/streamTiles/components/Tile.scss";

export const Placeholder = memo(() => (
  <div className={classnames(styles.root, styles.placeholder)}>
    <div className={styles.cover} />
  </div>
));

Placeholder.displayName = "Placeholder";

const Tile: React.FC<TileProps> = ({
  stream,
  linkState,
  className,
  showStreamerInfo = true,
}) => {
  const privateStream = [
    StreamKind.TICKET_PRIVATE,
    StreamKind.PRIVATE,
  ].includes(stream.kind);
  const isPremiumEnabled = usePremiumEnabled();
  const withAvatar = !showStreamerInfo;

  if (!isPremiumEnabled && privateStream) {
    return null;
  }

  return (
    <div
      className={classnames(styles.root, withAvatar && styles.space, className)}
    >
      <Link
        to={makeLinkToLiveStream(stream.id, linkState)}
        data-testid={`tile-${stream.id}`}
        className={styles.link}
      >
        <div className={styles.cover}>
          <Cover stream={stream} showStreamerInfo={showStreamerInfo} />
        </div>
      </Link>
      {withAvatar && <StreamerAvatar broadcasterId={stream.broadcasterId} />}
    </div>
  );
};

export default memo(Tile);
