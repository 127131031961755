import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { RegistrationSource } from "@analytics/enums";
import { GetTheAppSource } from "@analytics/getTheApp";
import { Breakpoints } from "src/enums";
import { GetTheAppMenuItem } from "src/features/getTheApp/exports/components";
import { VoidCallback } from "src/types/common";
import { RootState } from "state/delegate";
import { loginSelectors } from "state/selectors";
import JoinTangoBanner from "ui/common/banner/JoinTangoBanner";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Divider from "ui/common/divider/Divider";
import AuthorizedExploreNavigationItems from "ui/common/dropdownMenu/menuList/components/AuthorizedExploreItems";
import LegalLinks from "ui/common/dropdownMenu/menuList/components/LegalLinks";
import Logout from "ui/common/dropdownMenu/menuList/components/Logout";
import ProfileWithBroadcastButton from "ui/common/dropdownMenu/menuList/components/ProfileWithBroadcastButton";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import { ReactComponent as BackIcon } from "img/new-ui/burger_menu/ic-burger-mune-back.svg";
import styles from "ui/common/dropdownMenu/menuList/MenuList.scss";

interface MenuListProps {
  breakpoint: Breakpoints;
  className?: string;
  closeMenu: VoidCallback;
  isBurgerMenu: boolean;
}

const selectors = (state: RootState) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
});

const MenuList: React.FC<MenuListProps> = ({
  closeMenu,
  className,
  breakpoint,
  isBurgerMenu,
}) => {
  const { isLoggedIn } = useSelector(selectors, shallowEqual);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const intercept = useExclusiveClickHandler();
  const handleClose = useExclusiveClickHandler(closeMenu);

  return (
    <Typography
      type={isDesktop ? TYPOGRAPHY_TYPE.PARAGRAPH2 : TYPOGRAPHY_TYPE.PARAGRAPH1}
      onClick={isDesktop ? closeMenu : undefined}
      className={classnames(
        styles.root,
        styles[breakpoint],
        isBurgerMenu ? styles.left : styles.right,
        className
      )}
      as="div"
      data-testid={isBurgerMenu ? "burger-menu" : "profile-menu"}
    >
      <div className={styles.container}>
        {!isDesktop && (
          <div className={styles.header} onClick={intercept}>
            <Button
              className={styles.back}
              size={ButtonSize.MEDIUM_32}
              variant={ButtonVariant.ICON_ONLY}
              onClick={handleClose}
            >
              <BackIcon />
            </Button>
          </div>
        )}
        {(!isDesktop || !isBurgerMenu) && (
          <>
            {isLoggedIn ? (
              <ProfileWithBroadcastButton />
            ) : (
              <JoinTangoBanner
                className={styles.banner}
                layout={
                  breakpoint === Breakpoints.TABLET ? "horizontal" : "vertical"
                }
                screenSource={GetTheAppSource.BURGER_MENU}
                title={sharedMessages.joinTangoCommunity}
                registrationSource={RegistrationSource.SELF_PROFILE_ICON}
              />
            )}
            <Divider />
            {!isDesktop && !isBurgerMenu && <GetTheAppMenuItem withDivider />}

            <AuthorizedExploreNavigationItems breakpoint={breakpoint} />
          </>
        )}
        {(!isDesktop || isBurgerMenu) && <LegalLinks />}
        {isDesktop && !isBurgerMenu && isLoggedIn && (
          <GetTheAppMenuItem withDivider />
        )}
        {(!isDesktop || !isBurgerMenu) && isLoggedIn && (
          <>
            {(!isDesktop || isBurgerMenu) && <Divider />}
            <Logout onClick={closeMenu} />
          </>
        )}
      </div>
    </Typography>
  );
};

export default memo(MenuList);
