import React, { FC } from "react";
import {
  FormattedMessage,
  IntlProvider,
  FormattedDate as OriginalFormattedDate,
  FormattedNumber as OriginalFormattedNumber,
  FormattedTime as OriginalFormattedTime,
  useIntl,
} from "react-intl";
import { useSelector } from "react-redux";
import { Nullable } from "src/types/common";
import capitalize from "src/utils/capitalize";
import { deviceInfoSelectors } from "state/selectors";
import sharedMessages from "ui/common/intl/sharedMessages";
import numberToRoundedString, {
  RoundedNumberOptions,
} from "ui/utils/numberToRoundedString";

const DATE_FORMAT_MAX_LENGTH = 8;

const useGetLocale = (localeOverride: string) => {
  const locale: string = useSelector(deviceInfoSelectors.getDeviceLocale);
  if (locale === "ar") {
    // wrong format date/time for AR locale
    return localeOverride;
  }

  return locale;
};

interface DateProps {
  value: number;
}

export const FormattedNumber: typeof OriginalFormattedNumber = ({
  value,
  ...rest
}) => (
  <IntlProvider locale={useGetLocale("ar-EG")}>
    <OriginalFormattedNumber value={value} {...rest} />
  </IntlProvider>
);

export const FormattedDate: typeof OriginalFormattedDate = ({
  value,
  ...rest
}) => (
  <IntlProvider locale={useGetLocale("fr")}>
    <OriginalFormattedDate value={value} {...rest} />
  </IntlProvider>
);

export const FormattedTime: typeof OriginalFormattedTime = ({
  value,
  ...rest
}) => (
  <IntlProvider locale={useGetLocale("en")}>
    <OriginalFormattedTime value={value} {...rest} />
  </IntlProvider>
);

export const useNumToRoundedString = (
  number: Nullable<number | undefined>,
  options = {}
) => {
  const locale = useGetLocale("ar-EG");

  return numberToRoundedString(number, { locale, ...options });
};

interface RoundedNumberProps {
  options?: RoundedNumberOptions;
  value: Nullable<number | undefined>;
}

export const RoundedNumber: FC<RoundedNumberProps> = ({
  value,
  options = {} as RoundedNumberOptions,
}) => {
  const roundedString = useNumToRoundedString(value, options);

  return <span>{roundedString}</span>;
};

export const MessageDateNowOfflineChats: FC<DateProps> = ({ value }) => (
  <FormattedTime value={value} hour="2-digit" minute="2-digit" hour12={false} />
);

export const DateSeparatorOneDayNowOfflineChats: FC<DateProps> = ({
  value,
}) => {
  const intl = useIntl();
  const date = new Date(value);

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const dateTimestamp = new Date(date.setHours(0, 0, 0, 0)).getTime();

  if (date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    return <FormattedMessage id="date_format_today" defaultMessage="Today" />;
  } else if (dateTimestamp === yesterday.getTime()) {
    const yesterday = intl.formatMessage(sharedMessages.yesterday);

    return <>{capitalize(yesterday)}</>;
  } else if (dateTimestamp > oneWeekAgo.getTime()) {
    const weekday = intl.formatDate(value, { weekday: "long" });

    return <>{capitalize(weekday)}</>;
  }

  if (date.getFullYear() === new Date().getFullYear()) {
    return <FormattedDate value={value} day="numeric" month="long" />;
  }

  return (
    <FormattedDate value={value} day="numeric" month="short" year="numeric" />
  );
};

export const LastMessageDatePreviewOfflineChats: FC<DateProps> = ({
  value,
}) => {
  const intl = useIntl();
  const date = new Date(value);

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const dateTimestamp = new Date(date.setHours(0, 0, 0, 0)).getTime();

  if (dateTimestamp === now.getTime()) {
    return <MessageDateNowOfflineChats value={value} />;
  } else if (dateTimestamp === yesterday.getTime()) {
    const yesterday = intl.formatMessage(sharedMessages.yesterday);

    return <>{capitalize(yesterday)}</>;
  } else if (dateTimestamp > oneWeekAgo.getTime()) {
    const weekday = intl.formatDate(value, { weekday: "long" });

    return <>{capitalize(weekday)}</>;
  }

  const formattedDate = intl.formatDate(value, {
    day: "numeric",
    month: "long",
  });

  return (
    <FormattedDate
      value={value}
      day="numeric"
      month={formattedDate.length > DATE_FORMAT_MAX_LENGTH ? "short" : "long"}
    />
  );
};
