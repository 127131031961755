import React, { FC, memo, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import actions from "state/actionCreators/subscriptionPerStreamerList";
import { RootState } from "state/delegate";
import {
  profilesCacheSelectors,
  subscriptionPerStreamerListSelectors,
  userSelectors,
} from "state/selectors";
import { RoundedNumber } from "ui/common/Formatted";
import DiamondsCount from "ui/common/stats/DiamondsCount";
import Typography, {
  TYPOGRAPHY_TYPE,
  TypographyProps,
} from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { linkToSubscribers } from "ui/navigation/links";
import { ReactComponent as DiamondIcon } from "img/ic_diamond_32.svg";
import styles from "./Stats.scss";

interface FansStatProps {
  accountId: string;
  className: string;
  isMe: boolean;
  statTitleClassName?: string;
  typographyType: TypographyProps["type"];
}

const FanStat: FC<FansStatProps> = memo(
  ({ accountId, isMe, className, statTitleClassName, typographyType }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      if (isMe) return;

      dispatch(actions.refreshStreamerSubscribersList({ accountId }));
    }, [accountId, dispatch, isMe]);

    const fansCount = useSelector(
      useCallback(
        (state: RootState) =>
          isMe
            ? subscriptionPerStreamerListSelectors.getUserSubscribersIds(state)
                .length
            : subscriptionPerStreamerListSelectors.getStreamerSubscribersIds(
                state,
                accountId
              )?.length,
        [isMe, accountId]
      )
    );

    if (!fansCount) {
      return null;
    }

    const content = (
      <>
        <RoundedNumber value={fansCount} />
        <Typography
          type={typographyType}
          className={classnames(styles.title, statTitleClassName)}
        >
          <FormattedMessage id="profile.top.fans" defaultMessage="Fans" />
        </Typography>
      </>
    );

    return isMe ? (
      <Link className={className} to={linkToSubscribers}>
        {content}
      </Link>
    ) : (
      <div className={className}>{content}</div>
    );
  }
);

FanStat.displayName = "FanStat";

interface StatsProps {
  accountId: string;
  isMiniProfile?: boolean;
  statClassName?: string;
  statTitleClassName?: string;
}

const Stats: ComponentWithClassName<StatsProps> = ({
  accountId,
  className,
  isMiniProfile,
  statClassName,
  statTitleClassName,
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const statTypographyType = isDesktop
    ? TYPOGRAPHY_TYPE.PARAGRAPH3
    : TYPOGRAPHY_TYPE.PARAGRAPH5;
  const { followStats, myProfileId } = useSelector(
    useCallback(
      (state: RootState) => ({
        followStats: profilesCacheSelectors.getFollowStats(state, accountId),
        myProfileId: userSelectors.getMyAccountId(state),
      }),
      [accountId]
    ),
    shallowEqual
  );

  const statPreparedClassName = classnames(styles.stat, statClassName);
  const isMe = myProfileId === accountId;

  return (
    <Typography
      type={isDesktop ? TYPOGRAPHY_TYPE.HEADLINE2 : TYPOGRAPHY_TYPE.HEADLINE4}
      className={classnames(styles.root, styles[breakpoint], className)}
      as="div"
      data-testid="profile-statistics"
    >
      <div className={statPreparedClassName}>
        <DiamondsCount
          accountId={accountId}
          isDiamondViewToggleEnabled={!isMiniProfile}
        />
        <Typography
          type={statTypographyType}
          className={classnames(styles.title, statTitleClassName)}
        >
          <DiamondIcon className={styles.diamondIcon} />
          <FormattedMessage
            id="profile.header.stats.section.earned"
            defaultMessage="Earned"
          />
        </Typography>
      </div>
      <div className={statPreparedClassName}>
        <span data-testid="profile-statistics-followers">
          <RoundedNumber value={followStats?.followersCount || 0} />
        </span>
        <Typography
          type={statTypographyType}
          className={classnames(styles.title, statTitleClassName)}
        >
          <FormattedMessage
            id="leaderboard.followers"
            defaultMessage="Followers"
          />
        </Typography>
      </div>
      {isMe && (
        <div className={statPreparedClassName}>
          <span data-testid="profile-statistics-following">
            <RoundedNumber value={followStats?.followingCount || 0} />
          </span>
          <Typography
            type={statTypographyType}
            className={classnames(styles.title, statTitleClassName)}
          >
            <FormattedMessage
              id="live-feed.mode.following"
              defaultMessage="Following"
            />
          </Typography>
        </div>
      )}
      <FanStat
        accountId={accountId}
        isMe={isMe}
        className={statPreparedClassName}
        statTitleClassName={statTitleClassName}
        typographyType={statTypographyType}
      />
    </Typography>
  );
};

export default memo(Stats);
